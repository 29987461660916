<template>
	<div class="mhome">
		<div class="mhome-result">
			<template v-if="$route.params.id>0">
				<el-result v-if="orderInfo.status > 11" icon="success" :title="$t('mobile.successMsg.payment')" subTitle="">
					<template slot="extra">
						<el-button type="primary" size="medium" @click="$router.push({ name: 'mOrderList' })">
							{{ $t('mobile.actions.finish') }}</el-button>
					</template>
				</el-result>
				<el-result v-else :title="$t('mobile.infoMsg.inPayment')" subTitle="">
					<template slot="icon">
						<i class="el-icon-ship" style="font-size: 1.28rem;color:#1dc11d"></i>
					</template>
					<template slot="extra">
						<!-- <el-button type="primary" size="medium" @click="$router.push({ name: 'mOrderList' })">
							{{ $t('mobile.actions.finish') }}</el-button> -->
					</template>
				</el-result>
			</template>
			<template v-else>
				<el-result icon="success" :title="$t('mobile.successMsg.order')" subTitle="">
					<template slot="extra">
						<el-button type="primary" size="medium" @click="$router.push({ name: 'mOrderList' })">
							{{ $t('mobile.actions.finish') }}</el-button>
					</template>
				</el-result>
			</template>
		</div>
	</div>
</template>

<script>
import { getStatus } from '@/api/mobile/order'
export default {
	data() {
		return {
			orderInfo: {}
		}
	},
	created() {
		if(this.$route.params.id) {
			const timer = setInterval(() => {
				if(this.orderInfo.status>11) {
					clearInterval(timer)
				} else {
					this.getOrderInfo()
				}
			},1000)
		}
	},
	methods: {
		getOrderInfo() {
			getStatus({ order_id: this.$route.params.id }).then(response => {
				this.orderInfo = response.data
			}).catch(() => {
				this.$router.push({ name: 'mOrderList' })
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.mhome-result {
	height: 100%;
	background-color: #FFFFFF;
}
</style>
